import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Header from '../header'
import Footer from '../footer'
import '../layout.scss'

const Layout = ({
  children,
}) =>
  <Fragment>
    <Helmet>
      <html lang="en" />
      <title>Singular</title>
    </Helmet>

    <div id="layout" className="pure-g blog">
      <Header
        preTagLine="Dave Transom's"
        title="C&#8203;Sharp&#8203;Vitamins"
        tagLine="and other occasional goodies"
      >
        <ul className="nav-list">
            <li className="nav-item"><Link className="pure-button" to="/blog/">Blog</Link></li>
            <li className="nav-item"><Link className="pure-button" to="/tag/">Tags</Link></li>
            <li className="nav-item"><Link className="pure-button" to="/archive/">Archive</Link></li>

        </ul>
        <ul className="nav-list">
          <li className="nav-item"><Link className="pure-button" to="/#dave">Dave</Link></li>
          <li className="nav-item"><Link className="pure-button" to="/#rina">Rina</Link></li>
        </ul>
      </Header>

      <div className="content pure-u-1 pure-u-md-3-4">
          <div>
              {children}
              <Footer showLicense={true} />
          </div>
      </div>
    </div>
  </Fragment>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
