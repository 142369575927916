import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from './layout'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
        baseUri
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMM YYYY")
            title
          }
          excerpt(pruneLength: 480)
        }
      }
    }
  }
`

export default ({
  data: {
    site: { siteMetadata },
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges
    .filter(edge => edge.node.frontmatter.date)
    .map(edge => edge.node)

  return <Layout>
    <Helmet title={`Dave Transom's ${siteMetadata.blogTitle}`} />

    <div className="posts">
      <header className="post-header" style={{marginBottom: "4em"}}>
        <h1 className="post-title">Dave Transom's CSharpVitamins</h1>
        <p>An occasional blog about C#, Javascript and Web Development</p>
      </header>

      <section>
          { posts.map(post =>
            <div key={post.fields.slug} className="post">
              <h2 className="post-title">
                <Link to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
              </h2>

              <small className="text-muted">
                <i className="fa fa-fw fa-calendar" /> posted on <span className="text-nowrap">{post.frontmatter.date}</span>
              </small>

              <p className="post-description">
                {post.excerpt} <Link to={post.fields.slug}>
                  &raquo; more
                </Link>
              </p>
            </div>
          ) }
      </section>
    </div>
  </Layout>
}
