import React from 'react'

export default ({
  showLicense,
}) =>
  <footer className="footer">
    <div className="pure-menu pure-menu-horizontal pure-menu-open">
      <ul className="pure-menu-list">
        <li className="pure-menu-item">&copy; Copyright {(new Date()).getFullYear()} Singular Limited. All Rights Reserved.</li>
        <li className="pure-menu-item"><a href="#top">Back to top</a></li>
      </ul>
    </div>

    { showLicense &&
      <div className="pure-menu pure-menu-horizontal pure-menu-open">
        <ul className="pure-menu-list">
          <li className="pure-menu-item">Code and examples on this blog are <a href="/LICENSE.md">licensed under MIT</a>.</li>
        </ul>
      </div>
    }
  </footer>
