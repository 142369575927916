import React from 'react'
import Logo from './images/logo'
import Decor from './images/decor'

import './header.scss'

const Header = ({
  preTagLine,
  title,
  tagLine,
  children,
 }) =>
  <div className="sidebar pure-u-1 pure-u-md-1-4">

    <div className="sidebar-gradient" />
    <Decor className="sidebar-decor" />

    <div className="sidebar-brand">
      <Logo className="pure-img logo" alt="Singular Limited" />

      {preTagLine &&
        <h2 className="sidebar-brand-tagline mb-0">{preTagLine}</h2>
      }
      <h1 className="sidebar-brand-title mt-3">{title}</h1>
      <h2 className="sidebar-brand-tagline">{tagLine}</h2>

      <nav className="nav">
        {children}
      </nav>
    </div>
  </div>

export default Header
