import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Picture from 'gatsby-image'

const imgStyle = {
  objectPosition: "bottom left",
}

const style = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  zIndex: 1,
}

export default props =>
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "decor/daisies.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { fluid } = data.image.childImageSharp

      // doesn't suppor the webp images
      // return <img
      //   src={fluid.src}
      //   srcSet={fluid.srcSet}
      //   sizes={fluid.sizes}
      //   {...props}
      // />

      return <Picture fluid={fluid} imgStyle={imgStyle} style={style} {...props} />
    }}
  />
